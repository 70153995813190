import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { billingKeys } from "src/data/billing/queries/billingQueryCache"
import { callAssistKeys } from "src/data/callAssist/queries/callAssistQueryCache"
import {
  TCallAssistEstimateResponse,
  TCallAssistEstimateResponseErrors,
  TCallAssistEventResponse,
  TCallAssistFeedbackBody,
  TCallAssistGuestsResponse,
  TCallAssistGuestsResponseError,
  TCallAssistSubscriptionPatchRequestError,
  TCallAssistSubscriptionRequest,
  TCallAssistSubscriptionResponse,
} from "src/data/callAssist/types/callAssistTypes"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { profilesCache } from "src/data/homeProfiles/queries/homeProfilesCache"
import {
  useFetchProfile,
  usePatchProfile,
} from "src/data/homeProfiles/queries/HomeProfilesQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useFetchUserDecisions } from "src/data/userDecisions/userDecisionsQueries"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function useFetchCallAssistEventData({
  eventId,
  options,
}: {
  eventId: string
  options?: UseQueryOptions<
    TCallAssistEventResponse,
    AxiosError,
    TCallAssistEventResponse,
    ReturnType<typeof callAssistKeys.event>
  >
}) {
  async function fetchGuestAccessData() {
    const result = await minutApiHttpClient.get<TCallAssistEventResponse>(
      `${API_DEFAULT}/call_assist/feedback/${eventId}`
    )
    return result.data
  }

  return useQuery(callAssistKeys.event(eventId), fetchGuestAccessData, options)
}

export function usePostCallAssistFeedback({ eventId }: { eventId: string }) {
  async function postCallAssistFeeback(data: TCallAssistFeedbackBody) {
    const result = await minutApiHttpClient.post(
      `${API_DEFAULT}/call_assist/feedback/${eventId}`,
      data
    )
    return result.data
  }

  return useMutation(postCallAssistFeeback)
}

export function useFetchCallAssistState({
  profileId,
  level,
}: {
  profileId: string
  level: number
}) {
  return useFetchProfile({
    id: profileId,
    options: {
      select(data) {
        return { enabled: !!data.disturbance_monitoring?.call_assist }
      },
    },
  })
}

export function usePatchCallAssistState() {
  const patchProfile = usePatchProfile()

  function patchCallAssistState({
    profileId,
    enabled,
    level,
  }: {
    profileId: string
    enabled: boolean
    level: 1 | 2 | 3
  }) {
    return patchProfile.mutateAsync({
      id: profileId,
      data: { disturbance_monitoring: { call_assist: enabled } },
    })
  }

  return useMutation({
    mutationFn: patchCallAssistState,
  })
}

export function useFetchCallAssistActivationEstimate(props?: {
  additional_homes_quantity?: number
  options: UseQueryOptions<
    TCallAssistEstimateResponse,
    AxiosError<TCallAssistEstimateResponseErrors | undefined>,
    TCallAssistEstimateResponse,
    ReturnType<typeof callAssistKeys.estimate>
  >
}) {
  async function fetchCallAssistActivationEstimate() {
    const result = await minutApiHttpClient.get<TCallAssistEstimateResponse>(
      `${API_DEFAULT}/subscriptions/estimate/call_assist`,
      {
        params: { additional_homes_quantity: props?.additional_homes_quantity },
      }
    )
    return result.data
  }

  return useQuery({
    ...props?.options,
    queryKey: callAssistKeys.estimate(),
    queryFn: fetchCallAssistActivationEstimate,
  })
}

export function useFetchCallAssistSubscription<
  Select = TCallAssistSubscriptionResponse,
>({
  userId,
  options,
}: {
  userId: string
  options?: UseQueryOptions<
    TCallAssistSubscriptionResponse,
    AxiosError,
    Select,
    ReturnType<typeof callAssistKeys.subscription>
  >
}) {
  async function fetchCallAssistActivationEstimate() {
    const result =
      await minutApiHttpClient.get<TCallAssistSubscriptionResponse>(
        `${API_DEFAULT}/call_assist/users/${userId}/subscription`
      )
    return result.data
  }

  return useQuery({
    ...options,
    queryKey: callAssistKeys.subscription(userId),
    queryFn: fetchCallAssistActivationEstimate,
  })
}

export function usePatchCallAssistSubscription({ userId }: { userId: string }) {
  const queryClient = useQueryClient()
  async function patchCallAssistSubscription(
    data: TCallAssistSubscriptionRequest
  ) {
    const result = await minutApiHttpClient.patch(
      `${API_DEFAULT}/call_assist/users/${userId}/subscription`,
      data
    )
    return result.data
  }

  return useMutation<
    TCallAssistSubscriptionRequest,
    AxiosError<TCallAssistSubscriptionPatchRequestError>,
    TCallAssistSubscriptionRequest
  >({
    mutationFn: patchCallAssistSubscription,
    async onSuccess(data, variables, context) {
      queryClient.invalidateQueries(billingKeys.subscription())
      queryClient.invalidateQueries(profilesCache.all())
      return await queryClient.invalidateQueries(
        callAssistKeys.subscription(userId)
      )
    },
  })
}

export function useFetchCallAssistGuestlist({
  orgId,
  options,
}: {
  orgId: string
  options?: UseQueryOptions<
    TCallAssistGuestsResponse,
    AxiosError<TCallAssistGuestsResponseError | undefined>,
    TCallAssistGuestsResponse,
    ReturnType<typeof callAssistKeys.guests>
  >
}) {
  async function fetchCallAssistGuestList(): Promise<TCallAssistGuestsResponse> {
    const result = await minutApiHttpClient.get<TCallAssistGuestsResponse>(
      `${API_DEFAULT}/call_assist/organizations/${orgId}/guests`
    )
    return result.data
  }

  return useQuery({
    ...options,
    queryKey: callAssistKeys.guests(orgId),
    queryFn: fetchCallAssistGuestList,
  })
}

export function useGetCallAssistActive() {
  const { user_id, current_plan } = useGetUser()

  const useCallAssistFeature = useFeatureAvailability({
    feature: "use_call_assist",
  })

  const fetchCheckoutCallAssistActivated = useFetchUserDecisions({
    userId: user_id,
    filters: { decisions: "checkout_call_assist_activated" },
    options: { enabled: !!current_plan },
  })

  const callAssistFeatureAvailable = useCallAssistFeature.available

  const callAssistDecided =
    !!fetchCheckoutCallAssistActivated.data?.decisions?.length

  return {
    callAssistIsActive: callAssistFeatureAvailable || callAssistDecided,
    isInitialLoading:
      !useCallAssistFeature.ready ||
      fetchCheckoutCallAssistActivated.isInitialLoading,
    isError: fetchCheckoutCallAssistActivated.isError,
  }
}
